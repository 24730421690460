import { useCallback, useEffect, useMemo, useState } from 'react';

import { NextPage } from 'next';
import { getNonce } from 'lib/policy';
import { isTruthy } from 'utility/functions';
import { setIsOpenLoginModalValue } from 'features/modal/modalSlice';
import styles from './PageContent.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useInternalSession } from 'hooks/useInternalSession';
import { useRouter } from 'next/router';

const promoPath = (path: string): { pathname; search } => {
  let [pathname, search] = path.split('?');
  const params = new URLSearchParams(search);

  const segments = pathname.split('/').filter((_x, index) => index !== 1);

  const id_promo = params.get('id_promo');

  if (isTruthy(id_promo?.length)) {
    segments.push('dettaglio');
    segments.push(id_promo!);
    params.delete('id_promo');
  }

  return {
    search: params.toString(),
    pathname: segments.join('/'),
  };
};

const PageContent: NextPage = () => {
  const dispatch = useAppDispatch();

  const {
    session: { user },
  } = useInternalSession();

  const { asPath } = useRouter();
  const { pathname, search } = useMemo(() => promoPath(asPath), [asPath]);

  const [height, setHeight] = useState('100vh');

  const loginAction = useCallback(() => {
    dispatch(setIsOpenLoginModalValue(true));
  }, [dispatch]);

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin.includes(process.env.NEXT_PUBLIC_PROMO_URL!)) {
        const data = JSON.parse(event.data);
        const action = data.action;
        if (action === 'login') {
          loginAction();
        } else if (action === 'resize') {
          const offsetHeight = data.offsetHeight;
          setHeight(offsetHeight + 'px');
        } else if (action === 'routeChange') {
          window.scrollTo(0, 0);
        }
      }
    },
    [loginAction]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const url = useMemo(() => {
    const params = new URLSearchParams(search);
    const { token, cardNumber } = user ?? {};
    if (token) {
      params.set('token', token);
    }
    if (cardNumber) {
      params.set('carta', cardNumber);
    }

    return `${process.env.NEXT_PUBLIC_PROMO_URL}${pathname}${isTruthy(params.size) ? '?' : ''}${params.toString()}`;
  }, [pathname, search, user]);

  return (
    <iframe
      key={url}
      className={styles.frameContent}
      width="100%"
      height={height}
      src={url}
      nonce={getNonce()}
      suppressHydrationWarning
    ></iframe>
  );
};

export default PageContent;

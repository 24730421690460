import { Layout } from 'components/Layout';
import PageContent from 'components/page-content/promo/PageContent';
import { Seo } from 'components/seo';
import { cleanConfig } from 'lib/datoCms/utils';
import { getDatoCMSData } from 'sites/snai/lib/datoCms/queries/getDatoCMSData';

import { mergeTagsFromSeoData } from 'components/seo/utils';
import { getStaticPage } from 'lib/datoCms/queries/getStaticPage';
import { InferGetServerSidePropsType } from 'next';
import { isTruthy } from 'utility/functions';

const Promo = ({
  site,
  header,
  needHelp,
  footer,
  cmsContent,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { seoMetaTags, canonical } = cmsContent ?? {};

  return (
    <Layout header={header} needHelp={needHelp} footer={footer}>
      <Seo siteSeo={site} metaTags={seoMetaTags} canonicalUrl={canonical} />
      <PageContent />
    </Layout>
  );
};

export const getServerSideProps = async (context) => {
  const { locale, params } = context ?? {};
  const slug = Reflect.get(params ?? {}, 'slug') ?? [];

  console.log(`## promo (${slug})`);

  try {
    const prCMSData = getDatoCMSData('common', locale);
    const prStaticPage = getStaticPage('promo', locale);
    const [CMSData, pageData] = await Promise.all([prCMSData, prStaticPage]);

    const { site, header, needHelp, footer, config, allLabels: labels } = CMSData;

    const { allStaticPages, tabsWithAccordionModel: seoData } = pageData ?? {};

    if (!isTruthy(allStaticPages?.length)) {
      throw new Error(`CMS data - page promo: not found`);
    }

    const cmsContent = mergeTagsFromSeoData({
      seoData,
      cmsData: allStaticPages,
      fieldName: '0',
    })?.[0];

    return {
      props: {
        site,
        header,
        footer,
        labels,
        config: cleanConfig(config),
        needHelp,
        cmsContent: Object.assign({}, config, cmsContent),
      },
    };
  } catch (error) {
    console.log('error', error);
    return { notFound: true };
  }
};

export default Promo;
